<template>
  <div>
    <Loader v-if="loaderEnabled" v-bind:logo="loaderLogo"></Loader>
    <!-- MAIN CONTAINER -->
    <b-row>
      <b-col md="12" lg="12" sm="12">
        <KTCodePreview>
          <!-- CONTENT -->
          <template v-slot:preview>
            <!-- FILTER CONTENT -->
            <b-row class="mb-5">
              <b-col md="2">
                <b-form>
                  <vue-autosuggest
                    v-model="searchStock"
                    :suggestions="filteredOptions"
                    @selected="onSelected"
                    :limit="10"
                    @input="onInputChangeStore"
                    :input-props="inputPropStore"
                  >
                    <div
                      slot-scope="{ suggestion }"
                      style="display: flex; align-items: center"
                    >
                      {{ suggestion.item.name }}
                    </div></vue-autosuggest
                  >
                </b-form>
              </b-col>
              <b-col md="2">
                <b-input-group>
                  <date-picker
                    placeholder="Từ"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.fromDate"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="2">
                <b-input-group>
                  <date-picker
                    placeholder="Đến"
                    class="form-control form-control-sm"
                    :config="dpConfigs.date"
                    v-model="apiParams.toDate"
                  ></date-picker>
                </b-input-group>
              </b-col>
              <b-col md="1">
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="fetchMainData()"
                  >Lọc
                </b-button>
              </b-col>
            </b-row>
            <!-- LIST DATA -->
            <b-row>
              <b-col md="12" lg="12" sm="12">
                <b-table-simple class="table-bordered table-hover tbody-hover">
                  <b-thead>
                    <b-tr>
                      <b-th class="text-center">STT</b-th>
                      <b-th class="text-center">Cửa hàng</b-th>
                      <b-th class="text-center">Mã tài khoản</b-th>
                      <b-th class="text-center">Tên tài khoản</b-th>
                      <b-th class="text-center">Số dư đầu kỳ</b-th>
                      <b-th class="text-center">Tổng thu</b-th>
                      <b-th class="text-center">Tổng chi</b-th>
                      <b-th class="text-center">Số dư cuối kỳ</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody v-if="onLoadingList">
                    <b-tr>
                      <b-td colspan="8">
                        <vcl-table :speed="10" :animate="true" :columns="8" />
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tbody
                    v-for="(store, index) in mainList.dataset"
                    :key="store.storeId"
                  >
                    <b-tr
                      v-for="item in store.accountantSummaries"
                      :key="item.accountantId"
                    >
                      <b-td
                        :rowspan="store.accountantSummaries.length + 1"
                        class="text-center align-middle"
                        v-show="isShowRow(store.accountantSummaries, item)"
                        style="width: 20px"
                      >
                        {{ index + 1 + getOffsetItem }}
                      </b-td>
                      <b-td
                        :rowspan="store.accountantSummaries.length + 1"
                        class="text-left align-middle"
                        v-show="isShowRow(store.accountantSummaries, item)"
                        style="width: calc (20% - 20px)"
                      >
                        {{ store.storeName }}
                      </b-td>
                      <b-td class="text-left align-middle" style="width: 15%">
                        {{ item.accountantCode }}
                      </b-td>
                      <b-td class="text-left align-middle" style="width: 20%">
                        {{ item.accountantName }}
                      </b-td>
                      <b-td class="text-right align-middle" style="width: 11%">
                        {{ convertPrice(item.openingBalance) }}
                      </b-td>
                      <b-td class="text-right align-middle" style="width: 11%">
                        <router-link
                          :to="{
                            name: 'list-transaction',
                            params: {
                              searchProps: {
                                storeId: store.storeId,
                                fromDate: apiParams.fromDate,
                                toDate: apiParams.toDate,
                                type: '1',
                                accountantCode: item.accountantCode,
                              },
                              mode: 'cash',
                            },
                          }"
                        >
                          {{ convertPrice(item.cashIn) }}
                        </router-link>
                      </b-td>
                      <b-td class="text-right align-middle" style="width: 11%">
                        <router-link
                          :to="{
                            name: 'list-transaction',
                            params: {
                              searchProps: {
                                storeId: store.storeId,
                                fromDate: apiParams.fromDate,
                                toDate: apiParams.toDate,
                                type: '2',
                                accountantCode: item.accountantCode,
                              },
                              mode: 'cash',
                            },
                          }"
                        >
                          {{ convertPrice(item.cashOut) }}
                        </router-link>
                      </b-td>
                      <b-td class="text-right align-middle" style="width: 12%">
                        {{ convertPrice(item.closingBalance) }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tbody>
                    <b-tr>
                      <b-td
                        colspan="4"
                        class="text-right align-middle font-weight-bolder"
                        style="font-weight: 600,color: '#181c32'"
                      >
                        <span>Tổng</span>
                      </b-td>
                      <b-td class="text-right align-middle font-weight-bolder">
                        <span>{{
                          convertPrice(sumAmount('openingBalance'))
                        }}</span>
                      </b-td>
                      <b-td class="text-right align-middle font-weight-bolder">
                        <span>{{ convertPrice(sumAmount('cashIn')) }}</span>
                      </b-td>
                      <b-td class="text-right align-middle font-weight-bolder">
                        <span>{{ convertPrice(sumAmount('cashOut')) }}</span>
                      </b-td>
                      <b-td class="text-right align-middle font-weight-bolder">
                        <span>{{
                          convertPrice(sumAmount('closingBalance'))
                        }}</span>
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
            <!-- PAGINATION -->
            <b-row>
              <b-col lg="3" md="3" sm="12">
                <p class="mt-3 text-dark" style="font-weight: 500">
                  Tổng số:
                  {{ mainList.total }}
                </p>
              </b-col>
              <b-col lg="9" md="9" sm="12">
                <b-pagination-nav
                  class="custom-pagination"
                  v-show="mainList.pages >= 2"
                  :link-gen="linkGen"
                  :number-of-pages="mainList.pages"
                  use-router
                  @change="fetchMainData(pageInfo)"
                  align="right"
                  first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
                  prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
                  next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
                  last-class="page-item-last btn btn-icon btn-sm my-1 "
                  page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
                >
                  <template v-slot:first-text>
                    <span>
                      <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                    </span>
                  </template>

                  <template v-slot:prev-text>
                    <i class="ki ki-bold-arrow-back icon-xs"></i>
                  </template>

                  <template v-slot:next-text>
                    <i class="ki ki-bold-arrow-next icon-xs"></i>
                  </template>

                  <template v-slot:last-text>
                    <span class="text-info">
                      <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                    </span>
                  </template>
                </b-pagination-nav>
              </b-col>
            </b-row>
          </template>
        </KTCodePreview>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Loader from '@/view/content/Loader.vue';
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import { cmdUrl } from '@/utils/apiUrl';
import { getToastInstance } from '@/utils/toastHelper';
import { mapGetters } from 'vuex';
import moment from 'moment';
import _ from 'lodash';
// import { checkPermissions } from '../../../utils/saveDataToLocal';
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

export default {
  // Data of view.
  data() {
    return {
      title: 'Tổng hợp thu chi theo cửa hàng',
      listStores: [{ value: '', text: 'Tất cả cửa hàng' }],
      mainList: {
        dataset: [],
        total: 0,
        pages: 1,
      },
      apiParams: {
        page: 1,
        pageSize: 10,
        storeId: '',
        fromDate: moment().format('DD/MM/YYYY'),
        toDate: moment().format('DD/MM/YYYY'),
      },
      linkGen: (pageNum) => {
        this.apiParams.page = pageNum;
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      onLoadingList: false,
      dpConfigs: {
        date: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      filteredOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchStock: '',
      inputPropStore: {
        id: 'autosuggest__input',
        placeholder: 'Nhập cửa hàng',
        disabled: false,
      },
    };
  },
  // Component defination.
  components: {
    KTCodePreview,
    Loader,
    VclTable,
    datePicker,
  },
  // Mounted view.
  mounted() {
    this.loadBreadcum();
  },
  created() {
    this.loadBreadcum();
    this.fetchStore();
    this.fetchStoreByUser();
  },
  // Created view.
  methods: {
    ...getToastInstance(),
    fetchMainData: async function () {
      let page = this.$route.query.page || 1;
      if (!page) {
        page = 1;
      }
      this.apiParams.page = page;
      if (this.searchStock.trim() === '') {
        this.apiParams.storeId = null;
      }

      // Init request header.
      this.mainList.dataset = [];
      this.onLoadingList = true;
      ApiService.query(cmdUrl.Reports.accountingByStore, {
        params: {
          ...this.apiParams,
        },
      })
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch((err) => {
          const message = err.response.data.message;
          this.makeToastFailure(message);
          this.onLoadingList = false;
        });
    },
    formatDate: function (dateVal) {
      if (_.isEmpty(dateVal)) return '';
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    loadBreadcum: function () {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Báo cáo', route: '/reports' },
        { title: 'Kế toán' },
        { title: this.title },
      ]);
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchStore: async function () {
      ApiService.get('/stores/getStores').then((response) => {
        let data = {
          id: '',
          name: 'Tất cả cửa hàng',
          shortName: 'Tat ca cua hang',
        };
        this.optionsStore[0].data.push(data);
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
        });
        this.filteredOptions = [{ data: this.optionsStore[0].data }];
      });
    },
    isShow() {
      return true;
    },
    isShowRow: function (listData, item) {
      return _.findIndex(listData, ['accountantId', item.accountantId]) === 0;
    },
    sumAmount: function (dataKey) {
      const dataList = this.mainList.dataset;
      let sumOpeningBalance = 0;
      for (let index = 0; index < dataList.length; index++) {
        const element = dataList[index];
        sumOpeningBalance += _.reduce(
          element.accountantSummaries,
          (sum, item) => {
            return sum + parseFloat(item[dataKey]);
          },
          0
        );
      }
      return sumOpeningBalance;
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.apiParams.storeId = option.item.id;
      //this.onFilter();
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.searchStock = stores[0].name;
            this.apiParams.storeId = stores[0].id;
            this.inputPropStore.disabled = true;
          } else {
            this.optionsStore[0].data = [];
            const data = {
              id: '',
              name: 'Tất cả cửa hàng',
              shortName: 'Tat ca cua hang',
            };
            this.optionsStore[0].data.push(data);
            stores.map((element) => {
              this.optionsStore[0].data.push(element);
            });
            this.filteredOptions = [{ data: this.optionsStore[0].data }];
            this.searchStock = data.name;
            this.apiParams.storeId = data.id;
          }
        }

        this.fetchMainData();
      });
    },
  },
  computed: {
    ...mapGetters(['breadcrumbs', 'pageTitle', 'layoutConfig']),
    loaderEnabled() {
      return !/false/.test(this.layoutConfig('loader.type'));
    },
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig('loader.logo');
    },
    getOffsetItem() {
      return (this.apiParams.page - 1) * this.apiParams.pageSize;
    },
  },
  beforeRouteUpdate: function (to, from, next) {
    this.mode = to.params.mode;
    next();
  },
  watch: {
    mode: function (val) {
      this.mode = val;
      this.fetchMainData();
      this.loadBreadcum();
    },
  },
};
</script>

<style >
.sttClass {
  width: 20px;
}
.table tbody + tbody {
  border-top: unset !important;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.375rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5eaee;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
